export enum ChangeType {
    Changed = "changed",
    New = "new",
    Deleted = "deleted",
    Original = "original"
}

export enum TableMode {
    Normal = "normal",
    Import = "import",
    Compare = "compare"
}
