import React from 'react';

type Props = {
  copy: string;
  customStyle?: string;
  color?: string;
};

const ResetIconWithCopy = ({ copy, customStyle, color }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Icon/system/rotate</title>
        <g fill="none" fillRule="evenodd">
          <path
            d="M20.163 12.938v.074c-.45 1.876-1.413 3.425-2.888 4.65-1.4 1.176-3.1 1.763-5.1 1.763a8.39 8.39 0 0 1-3.038-.563 8.124 8.124 0 0 1-2.587-1.687l-1.388 1.387c-.175.126-.337.188-.487.188-.15 0-.312-.062-.487-.188A.66.66 0 0 1 4 18.076v-4.8c0-.2.062-.362.188-.488a.66.66 0 0 1 .487-.187h4.8c.2 0 .362.062.488.188.125.175.187.337.187.487 0 .15-.062.312-.188.487L8.5 15.226c.525.475 1.1.837 1.725 1.088.6.25 1.262.375 1.988.375.95 0 1.837-.225 2.662-.675.8-.45 1.475-1.088 2.025-1.913l.563-1.275c.05-.15.15-.225.3-.225h2.062c.125 0 .212.025.262.075.05.1.076.187.076.262zm.075-8.588v4.8a.66.66 0 0 1-.188.487.66.66 0 0 1-.488.188h-4.8a.66.66 0 0 1-.487-.188c-.125-.175-.188-.337-.188-.487 0-.15.063-.312.188-.488l1.5-1.462c-1.05-.975-2.3-1.463-3.75-1.463-.95 0-1.837.225-2.662.675-.8.45-1.475 1.088-2.025 1.913L6.775 9.6c-.05.15-.15.225-.3.225H4.338c-.126 0-.213-.025-.263-.075A.604.604 0 0 1 4 9.488v-.075c.45-1.876 1.412-3.425 2.888-4.65C8.313 3.587 10.024 3 12.024 3a8.39 8.39 0 0 1 3.037.563c1.026.425 1.9.987 2.626 1.687l1.387-1.388c.175-.125.337-.187.488-.187.15 0 .312.062.487.188a.66.66 0 0 1 .188.487z"
            fill={(color) ? color : "#236CFF"} />
        </g>
      </svg>
    </div>
    <div>{copy}</div>
  </div>
);

export default ResetIconWithCopy;
