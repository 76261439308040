import { createSlice } from '@reduxjs/toolkit';
import { deepCloneRoutingRule, IRoutingRule, IRoutingRuleSet, RoutingRuleChange } from '../models/RoutingRuleSetModel';
import { RoutingRulesAlert } from '../models/RoutingRulesAlert';
import { TestCase, TestResult, TestCaseResult, TestCaseChange, cloneTestCase, isEqualTestCase } from '../models/TestSuiteModel';
import { ArticleType, Brand, Channel } from '../models/RoutingRuleDropdownData';
import { ChangeType } from '../models/Common';
import { findNextMatch, getRoutingRuleHash, Match } from '../utils/ImportHelpers';

export interface RoutingRulesData {
    routingRuleSet: IRoutingRuleSet,
    importedRuleSet: IRoutingRuleSet,
    testCases: TestCase[],
    importedTestCases: TestCase[],
    articleTypes: ArticleType[],
    brands: Brand[],
    channels: Channel[],
    nrApiCallsInProgress: number,
    alert: RoutingRulesAlert | null;
};

const initialRoutingRulesState: RoutingRulesData = {
    routingRuleSet: {
        name: "",
        description: "",
        timestamp: "",
        version: 0,
        decisionRules: []
    },
    importedRuleSet: {
        name: "",
        description: "",
        timestamp: "",
        version: 0,
        decisionRules: []
    },
    testCases: [],
    importedTestCases: [],
    articleTypes: [],
    brands: [],
    channels: [],
    nrApiCallsInProgress: 0,
    alert: null
};

export const callTypes = {
    get: 'get',
    post: 'post',
    put: 'put',
    file: 'file'
};

export const routingRulesSlice = createSlice({
    name: 'routingRulesData',
    initialState: initialRoutingRulesState,
    reducers: {
        catchError: (state, action) => {
            state.alert = action.payload.alert;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            }
        },
        startCall: (state, action) => {
            state.alert = null;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = state.nrApiCallsInProgress + 1;
            }
        },
        dismissAlert: (state) => {
            state.alert = null;
        },
        routingRulesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.routingRuleSet = action.payload;
        },
        testCasesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.testCases = action.payload;
        },
        testSuiteResultsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const testResults = action.payload as TestResult[]
            state.testCases = state.testCases.map((test) => {
                const result = testResults.find((result) => test.id === result.testCase.id)
                return (result)
                    ? {
                        ...test,
                        testResult: {
                            failureReason: result.failureReason,
                            result: result.result,
                            selectedProductionSite: result.selectedProductionSite,
                            selectedRule: { ...result.selectedRule }
                        } as TestCaseResult
                    }
                    : test
            })
        },
        articleTypesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const articleTypes = action.payload.map((at: any) => {
                return {
                    ...at,
                    articles: at.articles.map((a: string) => a.toLowerCase())
                }
            }) as ArticleType[];
            articleTypes.sort((a: ArticleType, b: ArticleType) => a.name.localeCompare(b.name));
            articleTypes.forEach(at => at.articles.sort())
            state.articleTypes = articleTypes;
        },
        brandsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const brands = [...action.payload] as Brand[];
            brands.sort((a: Brand, b: Brand): number => {
                return a.name.localeCompare(b.name)
            });
            brands.forEach(brand => brand.channels.sort())

            state.brands = brands
        },
        channelsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const channels = [...action.payload] as Channel[];
            channels.sort((a: Channel, b: Channel): number => {
                //sort alphabelically on Brand, then channel
                if (a.brand !== b.brand) {
                    return a.brand.localeCompare(b.brand)
                }
                return a.name.localeCompare(b.name)
            });
            state.channels = channels
        },
        routingRulesImported: (state, action) => {
            const importedRoutingRules = action.payload.decisionRules as IRoutingRule[]
            importedRoutingRules.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
            const importedRoutingRuleHashes = importedRoutingRules.map(r => getRoutingRuleHash(r))
            const existingRoutingRules = state.routingRuleSet.decisionRules
            existingRoutingRules.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
            const existingRoutingRuleHashes = existingRoutingRules.map(r => getRoutingRuleHash(r))
            const comparedRoutingRules = [] as IRoutingRule[]

            function createNewOrChangedRule(importedRule: IRoutingRule) {
                //there exists an imported rule, with no equal existing rule
                const rule = deepCloneRoutingRule(importedRule);
                //see if there is an existing rule with the same id
                const existingRule = existingRoutingRules.find(x => x.id === rule.id);
                if (existingRule) {
                    //if there is one, then the rule has been changed, and the imported rule is added to the list as a Changed rule
                    rule.routingRuleChange = { changeType: ChangeType.Changed, originalRoutingRule: deepCloneRoutingRule(existingRule) } as RoutingRuleChange;
                } else {
                    //if there isn't one, the imported rule must be a truly new rule
                    rule.routingRuleChange = { changeType: ChangeType.New } as RoutingRuleChange;
                }
                return rule;
            }

            function createDeletedOrOriginalRule(existingRule: IRoutingRule) {
                //there exists an existing rule, with no equal imported rule
                const rule = deepCloneRoutingRule(existingRule)
                //see if there is an imported rule with the same id
                const importedRule = comparedRoutingRules.find(x => x.id === rule.id)
                if (importedRule) {
                    //if there is one, then the rule has been changed, and the existing rule is added to the list as an Original rule
                    rule.id = "original-" + rule.id
                    rule.routingRuleChange = { changeType: ChangeType.Original } as RoutingRuleChange
                } else {
                    //if there isn't one, the existing rule must be deleted in the import
                    rule.routingRuleChange = { changeType: ChangeType.Deleted, originalRoutingRule: deepCloneRoutingRule(rule) } as RoutingRuleChange
                }
                return rule;
            }

            //create an array ordered by sequenceNumber of imported and existing rules, with all changes marked as either New, Changed, Original or Deleted
            let lastMatch = { incomingIndex: -1, currentIndex: -1, foundMatch: false } as Match
            let nextMatch = findNextMatch(lastMatch, importedRoutingRuleHashes, existingRoutingRuleHashes)
            while (true) {
                if (nextMatch.foundMatch) {
                    //add incoming between matches as new or changed
                    importedRoutingRules.filter((r, x) => x > lastMatch.incomingIndex && x < nextMatch.incomingIndex)
                        .forEach(r => {
                            const rule = createNewOrChangedRule(r);
                            comparedRoutingRules.push(rule)
                        })
                    //add current between matches as original deleted
                    existingRoutingRules.filter((r, x) => x > lastMatch.currentIndex && x < nextMatch.currentIndex)
                        .forEach(r => {
                            const rule = createDeletedOrOriginalRule(r);
                            comparedRoutingRules.push(rule)
                        })
                    //add matched rule as unchanged
                    comparedRoutingRules.push(importedRoutingRules[nextMatch.incomingIndex])
                } else {
                    //final, add remaining incoming as new or changed, add remaining current as original or deleted
                    importedRoutingRules.filter((r, x) => x > lastMatch.incomingIndex)
                        .forEach(r => {
                            const rule = createNewOrChangedRule(r);
                            comparedRoutingRules.push(rule)
                        })
                    existingRoutingRules.filter((r, x) => x > lastMatch.currentIndex)
                        .forEach(r => {
                            const rule = createDeletedOrOriginalRule(r);
                            comparedRoutingRules.push(rule)
                        })
                    break;
                }
                lastMatch = nextMatch
                nextMatch = findNextMatch(lastMatch, importedRoutingRuleHashes, existingRoutingRuleHashes)
            }
            state.importedRuleSet = {
                name: action.payload.name,
                description: "Imported Routing rules",
                timestamp: action.payload.timestamp,
                version: action.payload.version,
                decisionRules: comparedRoutingRules
            } as IRoutingRuleSet
        },
        testCasesImported: (state, action) => {
            //Mark all imported testcases with the result of a comparison to the existing testcase
            const importedTestCases = action.payload as TestCase[]
            importedTestCases.forEach((importedTestCase) => {
                const currentTestCase = state.testCases.find(x => x.id === importedTestCase.id)
                const equalsCurrentTestCase = isEqualTestCase(importedTestCase, (currentTestCase ?? undefined))
                //Imported TestCase is New
                if (!currentTestCase) {
                    importedTestCase.testCaseChange = { changeType: ChangeType.New } as TestCaseChange
                }
                //Imported TestCase is Different
                if (currentTestCase && !equalsCurrentTestCase) {
                    importedTestCase.testCaseChange = { changeType: ChangeType.Changed, originalTestCase: currentTestCase } as TestCaseChange
                }
                //Imported TestCase is unchanged or reverts changes - no TestChange to record
            })
            //Create deletion records for testcases that are no longer present
            const deletedTestCases = state.testCases.filter((currentTestCase) => !importedTestCases.find(x => x.id === currentTestCase.id))
            deletedTestCases.forEach((deletedTestCase) => {
                const deletedByImport = cloneTestCase(deletedTestCase)
                deletedByImport.testCaseChange = {
                    originalTestCase: cloneTestCase(deletedTestCase),
                    changeType: ChangeType.Deleted
                } as TestCaseChange
                importedTestCases.push(deletedByImport)
            })
            state.importedTestCases = importedTestCases
        },
        clearRoutingRuleSetImport: (state) => {
            state.importedRuleSet = {
                name: "",
                description: "",
                timestamp: "",
                version: 0,
                decisionRules: []
            }
        },
        clearTestCasesImport: (state) => {
            state.importedTestCases = []
        },
        acceptRoutingRuleSetImport: (state) => {
            //Imported rules with Original are copies of current rules that have been Changed in the import. They should not be kept.
            const actualImports = state.importedRuleSet.decisionRules.filter(x => x.routingRuleChange?.changeType !== ChangeType.Original)
            state.routingRuleSet = {
                ...state.importedRuleSet,
                decisionRules: actualImports
            }
            state.importedRuleSet = {
                name: "",
                description: "",
                timestamp: "",
                version: 0,
                decisionRules: []
            }
        },
        acceptTestCasesImport: (state) => {
            state.testCases = [...state.importedTestCases]
            state.importedTestCases = []
        },
        upsertRoutingRule: (state, action) => {
            const newRule = action.payload;
            const ruleIsNew = state.routingRuleSet.decisionRules.find((stateRule) => stateRule.id === newRule.id) === undefined
            const needToReorderSequence = state.routingRuleSet.decisionRules.find((stateRule) =>
                stateRule.sequenceNumber === newRule.sequenceNumber && stateRule.id !== newRule.id && stateRule.routingRuleChange?.changeType !== ChangeType.Deleted) !== undefined
            const insertAtEnd = newRule.sequenceNumber < 0
            if (insertAtEnd) {
                //Calculate sequenceNumber for the rule to be upserted
                const maxSequenceNumber = Math.max(...state.routingRuleSet.decisionRules.map((r) => r.sequenceNumber))
                newRule.sequenceNumber = maxSequenceNumber + 1
            } else if (needToReorderSequence) {
                //Push all rules with a higher sequenceNumber down a spot to create room
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.sequenceNumber >= newRule.sequenceNumber && currentRule.id !== newRule.id && currentRule.routingRuleChange?.changeType !== ChangeType.Deleted) {
                        currentRule.sequenceNumber++
                    }
                    return currentRule;
                });
            }
            if (ruleIsNew) {
                //New rule will be inserted according to its sequenceNumber
                state.routingRuleSet.decisionRules.push(newRule)
            } else {
                //Replace current rule with new rule
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.id === newRule.id) {
                        return newRule;
                    }
                    return currentRule;
                });
            }
        },
        upsertTestCase: (state, action) => {
            const newTestCase = action.payload;
            const testCaseIsNew = state.testCases.find((stateTest) => stateTest.id === newTestCase.id) === undefined
            if (testCaseIsNew) {
                state.testCases.push(newTestCase)
            } else {
                //Replace current testcase with new testcase
                state.testCases = state.testCases.map((currentTest) => {
                    if (currentTest.id === newTestCase.id) {
                        return newTestCase;
                    }
                    return currentTest;
                });
            }
        },
        updateRuleSetDescription: (state, action) => {
            const description = action.payload
            state.routingRuleSet.description = description
        },
        discardTestResults: (state) => {
            state.testCases = state.testCases.map((test) => {
                return {
                    ...test,
                    testResult: undefined
                }
            });
        }
    }
});