import { Field, getIn, useFormikContext } from "formik"
import { Input } from "../../../../_metronic/_partials/controls"
import { formatLocalDateTime, localDateTimeToIso } from "../../utils/DateTimeHelpers"
import { EditTestCase } from "./TestCaseEditModal"

const TestCaseContextEditBlock = () => {
    const { setFieldTouched, setFieldValue, values } = useFormikContext()
    const editTestCase = values as EditTestCase

    return (
        <div className="border border-secondary mt-6">
            <p className="routing-rule-text-smallcaps ml-4 mt-4">Test Context</p>
            <div className="border-top border-secondary p-4">
                <div className="form-row">
                    <div className="form-group col-4 mb-3">
                        <label htmlFor="testcase-edit-evaluationDate">Evaluate as if on Date</label>
                        <Field
                            id="testcase-edit-evaluationDate"
                            name={"testContext.simulatedEvaluationDate"}
                            component={Input}
                            type="datetime-local"
                            value={formatLocalDateTime(getIn(values, "testContext.simulatedEvaluationDate")) ?? ''}
                            onChange={async (changeEvent: any) => {
                                const formattedDate = localDateTimeToIso(changeEvent.target.value)
                                await setFieldValue("testContext.simulatedEvaluationDate", formattedDate)
                                setFieldTouched("testContext.simulatedEvaluationDate", true)
                            }}
                        />
                    </div>
                    <div className="form-group col-4 mb-3">
                        <label htmlFor="testcase-edit-randomValue">Evaluate with factory split percentile</label>
                        <Field
                            component={Input}
                            id="testcase-edit-randomValue"
                            name="testContext.simulatedRandomValue"
                            value={(editTestCase.testContext?.simulatedRandomValue) ? (Math.round(editTestCase.testContext?.simulatedRandomValue * 100)) : ''}
                            type="text"
                            customFeedbackLabel={<div className="lighter">Percentiles are assigned to factories in alphabetical order</div>}
                            onChange={async (changeEvent: any) => {
                                const calculatedValue = Number(changeEvent.target.value) ? (changeEvent.target.value / 100).toFixed(2) : undefined
                                await setFieldValue("testContext.simulatedRandomValue", calculatedValue)
                                setFieldTouched("testContext.simulatedRandomValue", true)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestCaseContextEditBlock