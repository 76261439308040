import { useDispatch } from "react-redux";
import { SelectionReason, useRoutingRuleContext } from "../../RoutingRuleContext"
import * as actions from '../../redux/RoutingRulesActions'
import ExportCSV from "../../../components/ExportCSV";
import { convertTestCasesToCSVData } from "../../utils/TestCaseCSVHelpers";
import moment from "moment";
import { getEnvironment } from "../../../utils/GetEnvironment";
import { cleanRoutingRuleSetForPersistence } from "../../models/RoutingRuleSetModel";

type Props = {
}

const TestCasesActionCard = (props: Props) => {
    const dispatch = useDispatch();
    const routingRulesContext = useRoutingRuleContext();
    const changedTestCases = routingRulesContext.changedTestCases
    const changesPending = changedTestCases.length > 0
    const greenTestCases = routingRulesContext.availableTestCases.filter(tc => tc.testResult?.result === true).length
    const redTestCases = routingRulesContext.availableTestCases.filter(tc => tc.testResult?.result === false).length

    const handleAddTestCaseClick = () => {
        const newTestCase = {
            id: crypto.randomUUID(),
            targetProductionSite: "",
            testProduct: {
                channelName: "",
                destinationCountry: "",
                articleCode: "",
                productOptions: {}
            }
        }
        routingRulesContext.setSelectedTestCase({ testCase: newTestCase, selectionReason: SelectionReason.New })
        routingRulesContext.uiEvents.showTestCaseEditModal()
    }

    const handleRunTestCasesClick = () => {
        dispatch(actions.runTestCases(cleanRoutingRuleSetForPersistence(routingRulesContext.routingRuleSet), routingRulesContext.availableTestCases))
    }

    const handleTestSuiteImport = async (event: any) => {
        dispatch(actions.importTestCases(event.target.files[0]))
    }

    return (
        <div className="d-flex bg-secondary rounded p-3 row">
            <button
                className="border-0 bg-white rounded mx-2 col-2"
                onClick={handleRunTestCasesClick}
                onMouseDown={e => e.preventDefault()}
            >
                <div>Run TestSuite</div>
                {(greenTestCases > 0 || redTestCases > 0) &&
                    <div className="d-flex justify-content-center">
                        <div className="text-success">{`Green:${greenTestCases}`}</div>
                        <div className="text-danger ml-3">{`Red:${redTestCases}`}</div>
                    </div>
                }
            </button>
            <div className="col" />
            <ExportCSV
                data={convertTestCasesToCSVData(routingRulesContext.availableTestCases)}
                fileName={`routingRulesTestCases-${getEnvironment()}-${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`}
                className="border-0 bg-white rounded mx-2 col-2"
            >
                Export Test Cases
            </ExportCSV>
            <button
                className="border-0 bg-white rounded mx-2 col-2"
                disabled={changesPending}
                onMouseDown={e => e.preventDefault()}
            >
                <label className="px-3 pt-5 pb-3">
                    <input
                        type='file'
                        accept='.csv'
                        onChange={handleTestSuiteImport}
                        hidden
                    />
                    Import Test Cases
                </label>
            </button>
            <div className="col" />
            <button
                className="border-0 bg-white rounded mx-2 col-2"
                onClick={handleAddTestCaseClick}
                onMouseDown={e => e.preventDefault()}
            >
                <div className="d-flex justify-content-center align-content-center">
                    <div className="h1 text-primary mt-2">+</div>
                    <div className="ml-3 mt-4">Add new Test Case</div>
                </div>
            </button>
        </div>
    )
}

export default TestCasesActionCard