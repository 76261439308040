import { useEffect, useState } from "react";
import RoutingRulesCard from "../components/routingrules/RoutingRulesCard";
import RoutingRuleProvider from "../RoutingRuleContext";
import RoutingRuleDetailModal from "../components/routingruleDetailModal/RoutingRuleDetailModal";
import RoutingRuleEditModal from "../components/routingruleEditModal/RoutingRuleEditModal";
import RoutingRulesSaveCard from "../components/routingrules/RoutingRulesSaveCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import * as actions from '../redux/RoutingRulesActions'
import TestCasesCard from "../components/testcases/TestCasesCard";
import TestCaseEditModal from "../components/testcaseEditModal/TestCaseEditModal";
import TestCasesSaveCard from "../components/testcases/TestCasesSaveCard";
import { RoutingRulesAlert } from "../models/RoutingRulesAlert";
import TestCaseImportCompareModal from "../components/testcases/TestCaseImportCompareModal";
import "../routingRules.scss";

const RoutingRulesPage = () => {
  const [showRuleDetailsModal, setShowRuleDetailsModal] = useState<boolean>(false);
  const [showRuleEditModal, setShowRuleEditModal] = useState<boolean>(false);
  const [showTestCasesPage, setTestCasesPage] = useState<boolean>(false);
  const [showTestCaseEditModal, setShowTestCaseEditModal] = useState<boolean>(false);
  const [showTestCaseImportCompareModal, setShowTestCaseImportCompareModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchRoutingRules());
    dispatch(actions.fetchTestCases());
    dispatch(actions.fetchArticleTypes());
    dispatch(actions.fetchBrands());
  }, [dispatch]);

  const state = useSelector(
    (state: any) => ({
      alert: state.routingRulesData.alert || null,
    }),
    shallowEqual
  );
  const alert = state.alert as RoutingRulesAlert

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert())
  }

  const routingRulesUIEvents = {
    showRuleDetailsModal: () => { setShowRuleDetailsModal(true) },
    hideRuleDetailsModal: () => { setShowRuleDetailsModal(false) },
    showRuleEditModal: () => { setShowRuleEditModal(true) },
    hideRuleEditModal: () => { setShowRuleEditModal(false) },
    showTestCasePage: () => { setTestCasesPage(true) },
    showRoutingRulesPage: () => { setTestCasesPage(false) },
    showTestCaseEditModal: () => { setShowTestCaseEditModal(true) },
    hideTestCaseEditModal: () => { setShowTestCaseEditModal(false) },
    showTestCaseImportCompareModal: () => { setShowTestCaseImportCompareModal(true) },
    hideTestCaseImportCompareModal: () => { setShowTestCaseImportCompareModal(false) }
  }

  return (
    <RoutingRuleProvider routingRulesUIEvents={routingRulesUIEvents}>
      {alert && alert?.message && (
        <Alert variant="danger" onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.message}</Alert.Heading>
          {alert.url && <div>{`Call to ${alert.url}`}</div>}
          {alert.apiResponseCode && <div>{`ResponseCode: ${alert.apiResponseCode}`}</div>}
          {alert.apiMessage && <div>{`Message: ${alert.apiMessage}`}</div>}
          {alert.data && <div>{`Body: ${alert.data}`}</div>}
        </Alert>
      )}
      <div className="d-flex row">
        <RoutingRulesSaveCard />
        <TestCasesSaveCard />
      </div>
      {showTestCasesPage &&
        <>
          <TestCasesCard />
          {showTestCaseEditModal && <TestCaseEditModal />}
          {showTestCaseImportCompareModal && <TestCaseImportCompareModal />}
        </>}
      {!showTestCasesPage &&
        <>
          <RoutingRulesCard />
          {showRuleDetailsModal && <RoutingRuleDetailModal />}
          {showRuleEditModal && <RoutingRuleEditModal />}
        </>
      }
    </RoutingRuleProvider>
  )
}

export { RoutingRulesPage };