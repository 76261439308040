import { Card, CardBody, CardHeader, Input } from '../../../../_metronic/_partials/controls';
import { useDispatch } from "react-redux";
import { Field, Form, Formik, getIn } from 'formik';
import * as actions from '../../redux/RoutingRulesActions'
import { useRoutingRuleContext } from '../../RoutingRuleContext';
import { cleanRoutingRuleSetForPersistence } from '../../models/RoutingRuleSetModel';

type Props = {
    cardProps?: any;
}

const RoutingRulesSaveCard = (props: Props) => {
    const dispatch = useDispatch();
    const routingRuleContext = useRoutingRuleContext();

    const persistableRoutingRuleSet = cleanRoutingRuleSetForPersistence(routingRuleContext.routingRuleSet)
    const changedRoutingRules = routingRuleContext.changedRoutingRules
    const testCases = routingRuleContext.availableTestCases
    const changedTestCases = routingRuleContext.changedTestCases
    const nrApiCallsInProgress = routingRuleContext.nrApiCallsInProgress

    const someRuleChanges = changedRoutingRules.length > 0
    const someTestCaseChanges = changedTestCases.length > 0
    const greenTestSuite = testCases.length > 0 && testCases.filter((test) => test.testResult?.result === true).length === testCases.length
    const importInProgress = routingRuleContext.importedTestCases && routingRuleContext.importedTestCases.length > 0

    const saveData = {
        description: ""
    }

    return (
        <>
            {
                someRuleChanges && !importInProgress &&
                <Card
                    className="border border-primary routing-rules-changed mx-2 col"
                    {...props.cardProps}
                >
                    <CardHeader
                        className="border-0"
                        {...props.cardProps}
                    >
                        <h3 className='card-title card-label text-primary'>Unsaved Rule Changes</h3>
                    </CardHeader>
                    <CardBody {...props.cardProps}>
                        {(nrApiCallsInProgress > 0) &&
                            <div className='d-flex justify-content-center'>
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            </div>
                        }
                        <Formik
                            initialValues={saveData}
                            onSubmit={(saveData, formikBag) => {
                                dispatch(actions.persistRoutingRuleSet(persistableRoutingRuleSet, changedRoutingRules, saveData.description))
                                //We have a green testrun at this point, and want to save both rules (as requested), and associated tests to make sure updated tests are not left behind
                                if (someTestCaseChanges) {
                                    dispatch(actions.persistTestCases(testCases))
                                }
                                formikBag.setSubmitting(false)
                            }}>
                            {({ values }) => (
                                <Form className="form">
                                    <div className="form-row">
                                        <div className="form-group col-8">
                                            <label htmlFor="saveDate-edit-description">Change log description (optional)</label>
                                            <Field
                                                component={Input}
                                                id="saveDate-edit-description"
                                                name="description"
                                                type="string"
                                                placeholder='e.g. "Christmas Updates 2024, added 4 factories"'
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end row">
                                        <div className='d-flex align-items-baseline col-5'>
                                            <div>You have</div>
                                            <h3 className='px-2'>{changedRoutingRules.length}</h3>
                                            <div>unsaved change(s)</div>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                dispatch(actions.fetchRoutingRules())
                                                dispatch(actions.discardTestResults())
                                            }}
                                            className='btn btn-outline-theme border-2 fw-bold mx-1 col'>
                                            Discard Changes
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                dispatch(actions.runTestCases(persistableRoutingRuleSet, testCases, getIn(values, "description")))
                                                routingRuleContext.uiEvents.showTestCasePage()
                                                //We switch to the TestCase page here, to make sure the result of the RunTestCases call is presented immediately
                                            }}
                                            className='btn btn-primary-theme border-2 fw-bold mx-1 col'>
                                            Run TestSuite
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={!greenTestSuite}
                                            className='btn btn-primary-theme border-2 fw-bold mx-1 col'>
                                            Apply All Changes
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            }
        </>
    )
}

export default RoutingRulesSaveCard